@import 'styles/includes.scss';

.NotFoundPage {
    &__Title {
        @extend %h1;
    }
    &__Container {
        @extend %container-text;
        margin-top: 40px;
        margin-bottom: 40px;

        @include media(m) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }

    .Wysiwyg {
        padding: 0;
    }

    &__SearchModal {
        margin-top: 30px;

        @include media(m) {
            margin-top: 64px;
        }
    }
}
